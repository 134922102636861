import React from 'react';
import { Route, Navigate } from 'react-router-dom';

import AdminLayout from './layouts/AdminLayout';
import LabSelectionLayout from './layouts/LabSelectionLayout';
import Root from './layouts/Root';
import LabConfig from './pages/Admin/components/LabConfig';
import LabHistory from './pages/Admin/components/LabHistory';
import LiveLabs from './pages/Admin/components/LiveLabs';
import Recordings from './pages/Admin/components/Recordings';
import JoinedLabPage from './pages/JoinedLabPage';
import LabCodeEntryPage from './pages/LabCodeEntryPage';
import NotFoundPage from './pages/NotFoundPage';
import PrimaryLabPage from './pages/PrimaryLabPage';
import UnauthorizedPage from './pages/UnauthorizedPage';

const AppRoutes: React.ReactNode = (
  <Route element={<Root />}>
    <Route element={<LabSelectionLayout />}>
      <Route path="/" element={<LabCodeEntryPage />} />
    </Route>
    <Route>
      <Route path="/lab/:labSetId" element={<PrimaryLabPage />} />
      <Route path="/join/:labSetInstanceId" element={<JoinedLabPage />} />
    </Route>
    <Route path="/admin" element={<AdminLayout />}>
      <Route index element={<Navigate to="live" replace />} />
      <Route path="live" element={<LiveLabs />} />
      <Route path="history" element={<LabHistory />} />
      <Route path="lab-config" element={<LabConfig />} />
      <Route path="recordings" element={<Recordings />} />
    </Route>
    <Route path="/unauthorized" element={<UnauthorizedPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Route>
);

export default AppRoutes;
