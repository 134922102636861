import React from 'react';
import { Outlet } from 'react-router-dom';

import AdminHeader from '../pages/Admin/components/AdminHeader';

const AdminLayout: React.FC = () => {
  return (
    <div className="flex flex-col h-screen bg-blue-900 text-white overflow-auto">
      <AdminHeader />
      <main className="flex-grow">
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;
