import { ColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  FaFlask,
  FaArrowsRotate,
  FaTrash,
  FaChevronDown,
} from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import useApiClient, { UnauthorizedError } from '../../../api/apiClient';
import CopyButton from '../../../components/CopyButton';
import AdminTable from '../../../components/Table';
import { ResourceState } from '../../../models';
import { LabSetInstance } from '../../../models/LabSetInstance';

import ActionButton, { getButtonClasses } from './ActionButton';
import ModuleMenu from './ModuleMenu';

const LiveLabs: React.FC = () => {
  const [labSetInstances, setLabSetInstances] = useState<LabSetInstance[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    getLabSetInstances,
    shareLab,
    deleteLabSetInstance,
    resetLabSetInstance,
  } = useApiClient();
  const navigate = useNavigate();

  const initialColumnVisibility = {
    labSetInstanceId: false,
    labSetId: true,
    userId: true,
    state: true,
    expires: true,
    guacamoleUrl: false,
    joinLab: true,
  };

  const [loadingButtons, setLoadingButtons] = useState<{
    [key: string]: boolean;
  }>({});

  const [confirmationStates, setConfirmationStates] = useState<{
    [key: string]: boolean;
  }>({});

  const [moduleMenuStates, setModuleMenuStates] = useState<{
    [key: string]: boolean;
  }>({});

  const getWorkstationModules = (instance: LabSetInstance): string[] => {
    const modules: string[] = [];
    instance.workstations?.forEach((workstation) => {
      if (workstation.module) {
        modules.push(workstation.module);
      }
    });
    return modules;
  };

  const getLabModules = (instance: LabSetInstance): string[] => {
    const modules: string[] = [];
    instance.labs?.forEach((lab) => {
      if (lab.module) {
        modules.push(lab.module);
      }
    });
    return modules;
  };

  const getInstanceState = (instance: LabSetInstance): ResourceState => {
    // Check if any workstation is in RECREATING state
    const hasRecreatingWorkstation = instance.workstations?.some(
      (workstation) => workstation.state === ResourceState.RECREATING,
    );

    // Check if any lab is in RECREATING state
    const hasRecreatingLab = instance.labs?.some(
      (lab) => lab.state === ResourceState.RECREATING,
    );

    // If any component is recreating, the whole instance is considered recreating
    if (hasRecreatingWorkstation || hasRecreatingLab) {
      return ResourceState.RECREATING;
    }

    // Otherwise return the instance's own state
    return instance.state;
  };

  const toggleModuleMenu = (instanceId: string) => {
    setModuleMenuStates((prev) => ({
      ...prev,
      [instanceId]: !prev[instanceId],
    }));
  };

  const handleConfirmableAction = useCallback(
    (key: string, action: () => Promise<void>) => {
      if (!confirmationStates[key]) {
        setConfirmationStates((prev) => ({ ...prev, [key]: true }));
        setTimeout(() => {
          setConfirmationStates((prev) => ({ ...prev, [key]: false }));
        }, 2000);
        return;
      }

      setConfirmationStates((prev) => ({ ...prev, [key]: false }));
      action();
    },
    [confirmationStates],
  );

  const handleResetLab = useCallback(
    async (labSetInstanceId: string, module: string) => {
      const key = `reset-${labSetInstanceId}-${module}`;
      setLoadingButtons((prev) => ({ ...prev, [key]: true }));
      try {
        await resetLabSetInstance(labSetInstanceId, module);
        setLabSetInstances((prev) =>
          prev.map((instance) =>
            instance.labSetInstanceId === labSetInstanceId
              ? { ...instance, state: ResourceState.RECREATING }
              : instance,
          ),
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingButtons((prev) => ({ ...prev, [key]: false }));
        setModuleMenuStates((prev) => ({
          ...prev,
          [labSetInstanceId]: false,
        }));
      }
    },
    [resetLabSetInstance],
  );

  const handleDeleteLab = useCallback(
    (labSetInstanceId: string) => {
      const key = `delete-${labSetInstanceId}`;
      handleConfirmableAction(key, async () => {
        setLoadingButtons((prev) => ({ ...prev, [key]: true }));
        try {
          await deleteLabSetInstance(labSetInstanceId);
          setLabSetInstances((prev) =>
            prev.map((instance) =>
              instance.labSetInstanceId === labSetInstanceId
                ? { ...instance, state: ResourceState.DESTROYING }
                : instance,
            ),
          );
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingButtons((prev) => ({ ...prev, [key]: false }));
        }
      });
    },
    [deleteLabSetInstance, handleConfirmableAction],
  );

  const fetchLabSetInstances = useCallback(async () => {
    try {
      const instances = await getLabSetInstances();
      setLabSetInstances(instances);
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        navigate('/unauthorized');
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  }, [getLabSetInstances, navigate]);

  useEffect(() => {
    fetchLabSetInstances();

    // Set up interval to fetch data every minute
    const interval = setInterval(fetchLabSetInstances, 30000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleJoinLab = useCallback(
    async (labSetInstanceId: string, readOnly: boolean) => {
      const key = `${labSetInstanceId}-${readOnly}`;
      setLoadingButtons((prev) => ({ ...prev, [key]: true }));
      try {
        await shareLab(labSetInstanceId, readOnly);
        const url = `/join/${labSetInstanceId}`;
        window.open(url, '_blank');
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingButtons((prev) => ({ ...prev, [key]: false }));
      }
    },
    [shareLab],
  );

  const columns = useMemo<ColumnDef<LabSetInstance>[]>(
    () => [
      {
        accessorKey: 'labSetInstanceId',
        header: () => <div className="flex items-center">Lab Instance ID</div>,
        cell: ({ getValue }) => <CopyButton value={getValue() as string} />,
      },
      {
        accessorKey: 'labSetId',
        header: () => <div className="flex items-center">Lab Set ID</div>,
      },
      {
        accessorKey: 'userId',
        header: () => <div className="flex items-center">User ID</div>,
      },
      {
        accessorKey: 'state',
        header: () => <div className="flex items-center">State</div>,
        cell: ({ row }) => {
          return getInstanceState(row.original);
        },
      },
      {
        accessorKey: 'expires',
        header: () => <div className="flex items-center">Expires</div>,
        cell: ({ getValue }) => {
          const value = getValue() as number;
          return new Date(value * 1000).toLocaleString();
        },
      },
      {
        accessorKey: 'guacamoleUrl',
        header: () => <div className="flex items-center">Guacamole URL</div>,
      },
      {
        accessorKey: 'joinLab',
        header: 'Actions',
        enableSorting: false,
        cell: ({ row }) => {
          const value = row.original.labSetInstanceId as string;
          const collaborativeKey = `${value}-false`;
          const readOnlyKey = `${value}-true`;
          const isCollaborativeLoading =
            loadingButtons[collaborativeKey] || false;
          const isReadOnlyLoading = loadingButtons[readOnlyKey] || false;

          const instanceState = getInstanceState(row.original);
          const isDeployed = instanceState === ResourceState.DEPLOYED;

          return (
            <div className="flex flex-wrap gap-2">
              <ActionButton
                onClick={() => handleJoinLab(value, true)}
                disabled={isReadOnlyLoading || !isDeployed}
                loading={isReadOnlyLoading}
                icon={<></>}
                text="Join Read-Only"
                variant="secondary"
              />
              <ActionButton
                onClick={() => handleJoinLab(value, false)}
                disabled={isCollaborativeLoading || !isDeployed}
                loading={isCollaborativeLoading}
                icon={<></>}
                text="Join Collaborative"
                variant="primary"
              />
              <div className="relative">
                <button
                  onClick={() => toggleModuleMenu(value)}
                  disabled={!isDeployed}
                  className={getButtonClasses(
                    'yellow-500',
                    'yellow-500',
                    !isDeployed,
                    'justify-center space-x-2 min-w-[120px]',
                  )}
                  title="Reset Lab"
                >
                  <FaArrowsRotate className="w-4 h-4" />
                  <span>Reset</span>
                  <FaChevronDown
                    className={clsx(
                      'w-3 h-3 transition-transform',
                      moduleMenuStates[value] && 'transform rotate-180',
                    )}
                  />
                </button>
                {moduleMenuStates[value] && (
                  <div className="absolute z-10 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu">
                      <ModuleMenu
                        isOpen={true}
                        modules={getWorkstationModules(row.original)}
                        onModuleSelect={(module) =>
                          handleResetLab(value, module)
                        }
                        sectionTitle="Workstation Modules"
                        loadingStates={loadingButtons}
                        instanceId={value}
                      />
                      <ModuleMenu
                        isOpen={true}
                        modules={getLabModules(row.original)}
                        onModuleSelect={(module) =>
                          handleResetLab(value, module)
                        }
                        sectionTitle="Lab Modules"
                        loadingStates={loadingButtons}
                        instanceId={value}
                      />
                    </div>
                  </div>
                )}
              </div>
              <ActionButton
                onClick={() => handleDeleteLab(value)}
                disabled={loadingButtons[`delete-${value}`] || !isDeployed}
                loading={loadingButtons[`delete-${value}`]}
                icon={<FaTrash className="w-4 h-4" />}
                text={
                  confirmationStates[`delete-${value}`] ? 'Confirm?' : 'Delete'
                }
                variant="danger"
                minWidth
              />
            </div>
          );
        },
      },
    ],
    [handleJoinLab, loadingButtons],
  );

  return (
    <AdminTable
      title={{
        text: 'Live Labs',
        icon: <FaFlask />,
        tooltip: 'View and manage currently active lab instances',
      }}
      columnVisibility={initialColumnVisibility}
      data={labSetInstances}
      columns={columns}
      loading={loading}
      emptyMessage="No live labs available."
      globalFilterPlaceholder="Search labs..."
    />
  );
};

export default LiveLabs;
