import React from 'react';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  widthPercentage: number;
  children?: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  widthPercentage,
  children,
}) => {
  return (
    <>
      {/* Overlay */}
      <div
        className={`fixed inset-0 transition-opacity z-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
        aria-hidden="true"
      />

      {/* Drawer Panel */}
      <aside
        className={`fixed top-[4rem] right-0 bottom-0 z-50 bg-slate-800 text-black shadow-xl transform transition-transform ease-out duration-300 overflow-auto ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        style={{ width: `${widthPercentage}%` }}
        role="dialog"
        aria-modal="true"
      >
        <div>{children}</div>
      </aside>
    </>
  );
};

export default Drawer;
