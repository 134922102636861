import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useState, useMemo } from 'react';
import { FaTasks, FaExternalLinkAlt } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';

import useApiClient, { UnauthorizedError } from '../../../api/apiClient';
import CopyButton from '../../../components/CopyButton';
import AdminTable from '../../../components/Table';
import { Task } from '../../../models/Task';

const LabHistory: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [detailedView, setDetailedView] = useState<boolean>(false);
  const { getTasksForMonth } = useApiClient();
  const navigate = useNavigate();

  const getCurrentMonth = () => {
    const date = new Date();
    return date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
  };

  const getLastMonths = (numMonths: number) => {
    const months = [];
    const today = new Date();

    for (let i = 0; i < numMonths; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      months.push(
        date.toLocaleString('en-US', { month: 'short', year: 'numeric' }),
      );
    }

    return months;
  };

  useEffect(() => {
    if (!selectedMonth) {
      setSelectedMonth(getCurrentMonth());
      return;
    }

    const fetchTasks = async () => {
      setLoading(true);
      try {
        const fetchedTasks = await getTasksForMonth(selectedMonth);
        setTasks(fetchedTasks);
      } catch (error) {
        if (error instanceof UnauthorizedError) {
          navigate('/unauthorized');
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [navigate, selectedMonth]);

  const columns = useMemo<ColumnDef<Task>[]>(
    () => [
      {
        accessorKey: 'createdAt',
        header: () => <div className="flex items-center">Created At</div>,
        cell: ({ getValue }) => {
          const value = getValue() as number;
          return new Date(value * 1000).toLocaleString();
        },
      },
      {
        accessorKey: 'taskId',
        header: () => <div className="flex items-center">Task ID</div>,
        cell: ({ getValue }) => <CopyButton value={getValue() as string} />,
      },
      {
        accessorKey: 'labSetInstanceId',
        header: () => <div className="flex items-center">Lab Instance ID</div>,
        cell: ({ getValue }) => <CopyButton value={getValue() as string} />,
      },
      {
        accessorKey: 'labSetId',
        header: () => <div className="flex items-center">Lab Set</div>,
      },
      {
        accessorKey: 'userId',
        header: () => <div className="flex items-center">User ID</div>,
      },
      {
        accessorKey: 'action',
        header: () => <div className="flex items-center">Action</div>,
      },
      {
        accessorKey: 'state',
        header: () => <div className="flex items-center">State</div>,
      },
      {
        id: 'logs',
        header: () => <div className="flex items-center">Logs</div>,
        cell: ({ row }) => {
          // This encoding is necessary to handle special characters in logStreamName
          const logStreamName = (row.original.logStreamName || '')
            .replace(/\[/g, '%255B')
            .replace(/\]/g, '%255D')
            .replace(/\$/g, '%2524')
            .replace(/\//g, '%252F');
          const logsUrl = `${process.env.REACT_APP_LAB_LOG_PREFIX}${logStreamName}`;

          return (
            <button
              onClick={() => window.open(logsUrl, '_blank')}
              className="text-blue-600 hover:text-blue-800 flex items-center gap-1"
            >
              Open Logs
              <FaExternalLinkAlt size={12} />
            </button>
          );
        },
      },
      {
        id: 'recording',
        header: () => <div className="flex items-center">Recording</div>,
        cell: ({ row }) => {
          const createdAt = row.original.createdAt * 1000; // Convert to milliseconds

          // Date when recording feature was implemented
          const startDate = new Date('2024-12-30');
          const isInDateRange = createdAt >= startDate.getTime();

          if (!isInDateRange) {
            return null;
          }

          const labSetInstanceId = row.original.labSetInstanceId;
          return (
            <Link
              to={`/admin/recordings?lab_set_instance_id=${labSetInstanceId}`}
              className="text-blue-600 hover:text-blue-800 flex items-center gap-1"
            >
              View Recording
            </Link>
          );
        },
      },
    ],
    [],
  );

  const filteredData = useMemo(() => {
    if (detailedView) {
      return tasks;
    }
    return tasks.filter((task) => task.action === 'destroy_lab');
  }, [tasks, detailedView]);

  const [columnVisibility, setColumnVisibility] = useState({
    createdAt: true,
    taskId: false,
    labSetInstanceId: false,
    labSetId: true,
    userId: true,
    action: false,
    state: false,
    logs: true,
    recording: true,
  });

  useEffect(() => {
    setColumnVisibility((prev) => ({
      ...prev,
      action: detailedView,
      state: detailedView,
      labSetInstanceId: detailedView,
    }));
  }, [detailedView]);

  const filterPills = useMemo(
    () =>
      getLastMonths(12).map((month) => ({
        label: month,
        isActive: selectedMonth === month,
        onClick: () => setSelectedMonth(month),
      })),
    [selectedMonth],
  );

  return (
    <div>
      <AdminTable
        title={{
          text: 'Lab History',
          icon: <FaTasks />,
        }}
        columnVisibility={columnVisibility}
        data={filteredData}
        columns={columns}
        loading={loading}
        emptyMessage="No tasks available for this month."
        filterPills={filterPills}
        showDetailedViewToggle={true}
        isDetailedView={detailedView}
        onDetailedViewChange={setDetailedView}
      />
    </div>
  );
};

export default LabHistory;
