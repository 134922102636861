// beaker/src/context/LabContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useApiClient from '../api/apiClient';
import { useTheme } from '../context/ThemeContext';
import { LabSetInstance, ResourceState, LabSet } from '../models';
import { WebSocketLabStateChangedData } from '../models/WebSocketMessage';

import { useToast, ToastType } from './ToastContext';
import { useWebSocket } from './WebSocketContext';

export interface LabContextState {
  labSetInstanceId: string | null;
  labSetInstance: LabSetInstance | null;
  activeLabId: string | null;
  activeWorkstationId: string | null;
  labCatalog: LabSet[] | null;
  loadingMessage: string;
  isJoinedLab: boolean;
  setLabSetInstanceId: (labSetInstanceId: string | null) => void;
  setLabSetInstance: (details: LabSetInstance | null) => void;
  setActiveLabId: (labId: string | null) => void;
  setActiveWorkstationId: (workstationName: string | null) => void;
  setLoadingMessage: (message: string) => void;
  setIsJoinedLab: (isJoinedLab: boolean) => void;
}

export const LabContext = createContext<LabContextState | undefined>(undefined);

export const useLabContext = () => {
  const context = useContext(LabContext);
  if (!context) {
    throw new Error('useLabContext must be used within a LabProvider');
  }
  return context;
};

interface LabProviderProps {
  children: ReactNode;
}

export const LabProvider: React.FC<LabProviderProps> = ({ children }) => {
  const [labSetInstanceId, setLabSetInstanceId] = useState<string | null>(null);
  const [labSetInstance, setLabSetInstance] = useState<LabSetInstance | null>(
    null,
  );
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isJoinedLab, setIsJoinedLab] = useState(false);
  const [labCatalog, setLabCatalog] = useState<LabSet[] | null>(null);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { setTheme } = useTheme();
  const apiClient = useApiClient();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryActiveLabId = searchParams.get('active');
  const queryactiveWorkstationId = searchParams.get('workstation');

  const [activeLabId, setActiveLabIdState] = useState<string | null>(
    queryActiveLabId,
  );
  const [activeWorkstationId, setActiveWorkstationIdState] = useState<
    string | null
  >(queryactiveWorkstationId);

  const { updateLabViewState, subscribeToLabEvents, unsubscribeFromLabEvents } =
    useWebSocket();

  const isInitialLoadRef = useRef(true);
  const prevLabViewStateRef = useRef<LabSetInstance['labViewState'] | null>(
    null,
  );

  // Subscribe to lab events and handle LAB_STATE_CHANGED
  useEffect(() => {
    if (labSetInstanceId) {
      const handlers = {
        onLabStateChanged: (data: WebSocketLabStateChangedData) => {
          onLabStateChanged(data);
        },
      };

      subscribeToLabEvents(labSetInstanceId, handlers);

      return () => {
        unsubscribeFromLabEvents(labSetInstanceId);
      };
    }
  }, [labSetInstanceId]);

  const onLabStateChanged = (data: WebSocketLabStateChangedData) => {
    setLabSetInstance(data.labSetInstance);
    updateLoadingMessage(data.labSetInstance);
  };

  const updateLoadingMessage = (instance: LabSetInstance) => {
    switch (instance.state) {
      case ResourceState.DEPLOYED:
        setLoadingMessage('');
        break;
      case ResourceState.CREATING:
        setLoadingMessage('Creating your lab');
        break;
      case ResourceState.ERRORED:
        showToast(
          'An unknown error occurred. Please contact support for assistance.',
          ToastType.FAILURE,
          30000,
        );
        navigate('/');
        break;
      case ResourceState.DESTROYING:
        navigate('/');
        break;
      default:
        showToast(`Unexpected lab state: ${instance.state}`, ToastType.FAILURE);
        navigate('/');
    }
  };

  // Update activeLabId and activeWorkstationId based on labSetInstance changes
  useEffect(() => {
    if (labSetInstance) {
      if (isInitialLoadRef.current) {
        // On initial load, use query parameters or labViewState
        let initialActiveLabId =
          queryActiveLabId || labSetInstance.labViewState?.activeLabId;

        if (!initialActiveLabId && labSetInstance.labs.length > 0) {
          // If no activeLabId, pick the first lab
          initialActiveLabId = labSetInstance.labs[0].labId;
        }
        setActiveLabId(initialActiveLabId || null);

        let initialactiveWorkstationId =
          queryactiveWorkstationId ||
          labSetInstance.labViewState?.activeWorkstationIdId;

        if (
          !initialactiveWorkstationId &&
          labSetInstance.workstations.length > 0
        ) {
          // If no activeWorkstationId, pick the first workstation
          initialactiveWorkstationId =
            labSetInstance.workstations[0].workstationId;
        }
        setActiveWorkstationIdState(initialactiveWorkstationId || null);

        isInitialLoadRef.current = false;
      } else {
        // On subsequent labSetInstance changes, update activeLabId and activeWorkstationId
        const newLabViewState = labSetInstance.labViewState;
        const prevLabViewState = prevLabViewStateRef.current;

        // Active Lab
        if (
          newLabViewState?.activeLabId !== prevLabViewState?.activeLabId &&
          activeLabId !== newLabViewState?.activeLabId
        ) {
          if (newLabViewState?.activeLabId) {
            setActiveLabId(newLabViewState.activeLabId);
          } else if (labSetInstance.labs.length > 0) {
            setActiveLabId(labSetInstance.labs[0].labId);
          }
        }

        // Active Workstation
        if (
          newLabViewState?.activeWorkstationIdId !==
            prevLabViewState?.activeWorkstationIdId &&
          activeWorkstationId !== newLabViewState?.activeWorkstationIdId
        ) {
          if (newLabViewState?.activeWorkstationIdId) {
            setActiveWorkstationIdState(newLabViewState.activeWorkstationIdId);
          } else if (labSetInstance.workstations.length > 0) {
            setActiveWorkstationIdState(
              labSetInstance.workstations[0].workstationId,
            );
          }
        }

        prevLabViewStateRef.current = newLabViewState;
      }
    }
  }, [labSetInstance]);

  // Update backend labViewState when activeLabId or activeWorkstationId changes
  useEffect(() => {
    if (isJoinedLab) {
      // Do not attempt to update lab view state if we are joined to this lab
      return;
    }

    if (labSetInstance && labSetInstance.state === ResourceState.DEPLOYED) {
      const newLabViewState = {
        ...labSetInstance.labViewState,
        activeLabId,
        activeWorkstationIdId: activeWorkstationId,
      };

      updateLabViewState(labSetInstance.labSetInstanceId, newLabViewState);
    }
  }, [activeLabId, activeWorkstationId, labSetInstance, isJoinedLab]);

  // Update query params when activeLabId or activeWorkstationId changes
  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (activeLabId) {
      newSearchParams.set('active', activeLabId);
    } else {
      newSearchParams.delete('active');
    }

    if (activeWorkstationId) {
      newSearchParams.set('workstation', activeWorkstationId);
    } else {
      newSearchParams.delete('workstation');
    }

    setSearchParams(newSearchParams);
  }, [activeLabId, activeWorkstationId]);

  // Ensure activeLabId is valid or set default when labs change
  useEffect(() => {
    // Never set activeLabId if the lab set instance is not deployed
    if (!labSetInstance || labSetInstance.state !== ResourceState.DEPLOYED) {
      return;
    }

    // This should never be true, and is more on an assertion
    if (labSetInstance.labs.length == 0) {
      setLoadingMessage('No labs found');
      return;
    }

    const activeLab = labSetInstance.labs.find(
      (lab) => lab.labId === activeLabId,
    );

    // If active lab is valid, no further action is needed
    if (activeLab && activeLab.state === ResourceState.DEPLOYED) {
      return;
    }

    const deployedLabs = labSetInstance.labs.filter(
      (lab) => lab.state === ResourceState.DEPLOYED,
    );

    // In this case, the active lab is not valid and we need to set a new one, so we
    // either pick the first deployed lab or set a loading message if none are deployed
    if (deployedLabs.length > 0) {
      setActiveLabId(deployedLabs[0].labId);
      setLoadingMessage('');
    } else {
      setActiveLabId(null);
      setLoadingMessage('Recreating your lab');
    }
  }, [labSetInstance?.labs, activeLabId]);

  // Ensure activeWorkstationId is valid or set default when workstations change
  useEffect(() => {
    if (labSetInstance && labSetInstance.workstations.length > 0) {
      if (
        !activeWorkstationId ||
        !labSetInstance.workstations.some(
          (ws) => ws.workstationId === activeWorkstationId,
        )
      ) {
        setActiveWorkstationIdState(
          labSetInstance.workstations[0].workstationId,
        );
      }
    }
  }, [labSetInstance?.workstations, activeWorkstationId]);

  // Load lab catalog
  useEffect(() => {
    const fetchLabs = async () => {
      const labs = await apiClient.getLabCatalog();
      if (labs) {
        setLabCatalog(labs);
      }
    };

    fetchLabs();
  }, []);

  const setActiveLabId = (labId: string | null) => {
    attemptSetTheme(labId);
    setActiveLabIdState(labId);
  };

  const attemptSetTheme = (labId: string | null) => {
    if (labSetInstance) {
      const newLab = labSetInstance.labs.find((lab) => lab.labId === labId);
      setTheme(newLab?.uiTheme || 'default');
    } else {
      setTheme('default');
    }
  };

  const setActiveWorkstationId = (workstationId: string | null) => {
    setActiveWorkstationIdState(workstationId);
  };

  const contextValue: LabContextState = {
    labSetInstanceId,
    labSetInstance,
    activeLabId,
    activeWorkstationId,
    loadingMessage,
    isJoinedLab,
    labCatalog,
    setLabSetInstanceId,
    setLabSetInstance,
    setActiveLabId,
    setActiveWorkstationId,
    setLoadingMessage,
    setIsJoinedLab,
  };

  return (
    <LabContext.Provider value={contextValue}>{children}</LabContext.Provider>
  );
};
