import React from 'react';

interface HeaderButtonProps {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  iconOnly?: boolean;
}

const HeaderButton: React.FC<HeaderButtonProps> = ({
  icon,
  label,
  onClick,
  disabled = false,
  iconOnly = false,
}) => {
  return (
    <button
      className={`relative group flex items-center justify-center px-4 py-2 h-10 rounded-lg shadow transition duration-200 ${
        disabled
          ? 'bg-secondary-400 cursor-not-allowed'
          : 'bg-primary-600 hover:bg-primary-500 text-white'
      }`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <span className={`${iconOnly ? '' : 'mr-2'}`}>{icon}</span>
      {!iconOnly && label}
      {iconOnly && (
        <div className="absolute right-1/2 transform translate-x-1/2 -bottom-12 z-50 py-2 px-4 bg-gray-800 text-white text-sm rounded whitespace-nowrap opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
          {label}
        </div>
      )}
    </button>
  );
};

export default HeaderButton;
