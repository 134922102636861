import clsx from 'clsx';
import React from 'react';

interface ModuleMenuProps {
  isOpen: boolean;
  modules: string[];
  onModuleSelect: (module: string) => void;
  sectionTitle: string;
  loadingStates: { [key: string]: boolean };
  instanceId: string;
}

const ModuleMenu: React.FC<ModuleMenuProps> = ({
  isOpen,
  modules,
  onModuleSelect,
  sectionTitle,
  loadingStates,
  instanceId,
}) => {
  if (!isOpen || modules.length === 0) return null;

  return (
    <>
      <div className="px-3 py-1 text-xs font-semibold text-gray-500 uppercase tracking-wider">
        {sectionTitle}
      </div>
      {modules.map((module) => {
        const key = `reset-${instanceId}-${module}`;
        const isLoading = loadingStates[key] || false;
        return (
          <button
            key={module}
            onClick={() => onModuleSelect(module)}
            disabled={isLoading}
            className={clsx(
              'w-full px-4 py-2 text-sm text-left hover:bg-gray-100',
              isLoading && 'opacity-50 cursor-not-allowed',
            )}
            role="menuitem"
          >
            {isLoading ? (
              <span
                className="spinner mr-2"
                style={{
                  borderColor: '#1a1a1a transparent #1a1a1a transparent',
                }}
              ></span>
            ) : (
              <span className="block truncate">{module}</span>
            )}
          </button>
        );
      })}
    </>
  );
};

export default ModuleMenu;
