import { LabSetInstance } from './LabSetInstance';
import { LabViewState } from './LabViewState';

export enum WebSocketEventType {
  ERROR = 'ERROR',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  LAB_STATE_CHANGED = 'LAB_STATE_CHANGED',
  SUBSCRIBE_TO_LAB_CHANGES = 'SUBSCRIBE_TO_LAB_CHANGES',
  UPDATE_LAB_VIEW_STATE = 'UPDATE_LAB_VIEW_STATE',
  PING = 'PING',
  PONG = 'PONG',
}

export interface WebSocketErrorData {
  eventType: WebSocketEventType.ERROR;
  message: string;
}

export interface WebSocketUnauthenticatedData {
  eventType: WebSocketEventType.UNAUTHENTICATED;
}

export interface WebSocketUnauthorizedData {
  eventType: WebSocketEventType.UNAUTHORIZED;
}

export interface WebSocketLabStateChangedData {
  eventType: WebSocketEventType.LAB_STATE_CHANGED;
  labSetInstanceId: string;
  labSetInstance: LabSetInstance;
}

export interface WebSocketSubscribeToLabChangesData {
  eventType: WebSocketEventType.SUBSCRIBE_TO_LAB_CHANGES;
  labSetInstanceId: string;
}

export interface WebSocketUpdateLabViewStateData {
  eventType: WebSocketEventType.UPDATE_LAB_VIEW_STATE;
  labSetInstanceId: string;
  labViewState: LabViewState;
}

export interface WebSocketPingData {
  eventType: WebSocketEventType.PING;
}

export interface WebSocketPongData {
  eventType: WebSocketEventType.PONG;
}

export interface WebSocketMessage {
  data:
    | WebSocketErrorData
    | WebSocketUnauthenticatedData
    | WebSocketUnauthorizedData
    | WebSocketLabStateChangedData
    | WebSocketSubscribeToLabChangesData
    | WebSocketUpdateLabViewStateData
    | WebSocketPingData
    | WebSocketPongData;
}
