import React, { useContext, useState } from 'react';
import { FaBook, FaHome } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';

import Logo from '../assets/images/logo.svg';
import Title from '../assets/images/site_title.svg';

import Catalog from './Catalog';
import Drawer from './Drawer';
import HeaderButton from './HeaderButton';

interface HeaderProps {
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLogout = async () => {
    console.log('Logout initiated');

    try {
      localStorage.clear();
      sessionStorage.clear();
      const backendLogoutWindow = window.open(
        'https://auth.dev.mantarange.com/logout',
        '_blank',
      );

      setTimeout(async () => {
        if (backendLogoutWindow) {
          backendLogoutWindow.close();
        }
        if (auth && auth.logOut) {
          await auth.logOut();
        }
      }, 1000);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <header className="flex justify-between items-center px-6 py-3 h-16 bg-primary-800 text-white shadow-md border-b border-primary-700">
        <div className="flex items-center space-x-4">
          <img
            src={Logo}
            alt="logo"
            className="h-10 select-none"
            draggable="false"
          />
          <img
            src={Title}
            alt="title"
            className="h-7 select-none"
            draggable="false"
          />
        </div>
        <div
          className="flex items-center space-x-4"
          data-testid="header-controls"
        >
          {children}
          <HeaderButton
            icon={<FaBook />}
            label="Catalog"
            onClick={handleDrawerOpen}
          />
          <HeaderButton
            icon={<FaHome />}
            label="Home"
            onClick={() => navigate('/')}
            iconOnly={true}
          />
          <HeaderButton
            icon={<FiLogOut />}
            label="Logout"
            onClick={handleLogout}
            iconOnly={true}
          />
        </div>
      </header>

      <Drawer
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
        widthPercentage={30}
      >
        <Catalog />
      </Drawer>
    </>
  );
};

export default Header;
