/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import debounce from 'lodash/debounce';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { FaTools } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

interface ColumnHeaderWithTooltipProps {
  title: string;
  tooltip?: string;
  tooltipPosition?: 'left' | 'right' | 'center';
}

const ColumnHeaderWithTooltip: React.FC<ColumnHeaderWithTooltipProps> = ({
  title,
  tooltip,
  tooltipPosition = 'center',
}) => {
  if (!tooltip) {
    return <div className="flex items-center">{title}</div>;
  }

  const positionClasses = {
    left: 'right-full mr-2',
    right: 'right-0',
    center: 'left-1/2 -translate-x-1/2 bottom-full mb-2',
  };

  return (
    <div className="flex items-center gap-2">
      {title}
      <div className="relative group">
        <FiInfo className="text-blue-600 hover:text-blue-800" />
        <div
          className={`absolute ${positionClasses[tooltipPosition]} w-64 p-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity z-50 max-w-[calc(100vw-2rem)] whitespace-normal break-words`}
        >
          {tooltip}
        </div>
      </div>
    </div>
  );
};

import useApiClient, { UnauthorizedError } from '../../../api/apiClient';
import CopyButton from '../../../components/CopyButton';
import AdminTable from '../../../components/Table';
import { useToast, ToastType } from '../../../context/ToastContext';
import { LabSet } from '../../../models/LabSet';

interface EditableCellProps {
  value: number;
  row: any;
  column: any;
  updateData: (rowIndex: number, columnId: string, value: number) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  value: initialValue,
  row,
  column,
  updateData,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (newValue: string) => {
    const numValue = parseInt(newValue) || 0;
    if (numValue >= 0 && numValue <= 100) {
      setValue(numValue);
      updateData(row.index, column.id, numValue);
    }
  };

  return (
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-16 px-2 py-1 border rounded text-center focus:outline-none focus:ring-1 focus:ring-blue-500"
      type="number"
      min="0"
      max="100"
      style={{
        WebkitAppearance: 'none',
        MozAppearance: 'textfield',
      }}
    />
  );
};

const LabConfig: React.FC = () => {
  const [labSets, setLabSets] = useState<LabSet[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { getLabSets, upsertLabSet } = useApiClient();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const initialColumnVisibility = {
    labSetId: false,
    labSetName: true,
    audienceSize: true,
    extraLabCount: true,
    expirationHours: true,
    allowedGroups: true,
    description: false,
    imageUrl: false,
    terraformS3Uri: false,
    guacamoleInstanceId: false,
    excludeFromCatalog: true,
    loadingTimeSeconds: true,
  };

  useEffect(() => {
    const fetchLabSets = async () => {
      setLoading(true);
      try {
        const fetchedLabSets = await getLabSets();
        setLabSets(fetchedLabSets);
      } catch (error) {
        if (error instanceof UnauthorizedError) {
          navigate('/unauthorized');
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchLabSets();
  }, [navigate]);

  const debouncedUpsert = useMemo(
    () =>
      debounce(async (labSet: LabSet) => {
        try {
          await upsertLabSet(labSet);
          showToast(
            'Lab configuration updated successfully',
            ToastType.SUCCESS,
          );
        } catch (error) {
          console.error('Failed to update lab set:', error);
          showToast('Failed to update lab configuration', ToastType.FAILURE);
        }
      }, 1500),
    [upsertLabSet],
  );

  const updateData = useCallback(
    (rowIndex: number, columnId: string, value: number) => {
      setLabSets((old) => {
        const newLabSets = [...old];
        const labSetToUpdate = { ...newLabSets[rowIndex], [columnId]: value };
        newLabSets[rowIndex] = labSetToUpdate;
        debouncedUpsert(labSetToUpdate);
        return newLabSets;
      });
    },
    [labSets, debouncedUpsert],
  );

  const columns = useMemo<ColumnDef<LabSet>[]>(
    () => [
      {
        accessorKey: 'labSetId',
        header: () => <div className="flex items-center">Lab Set ID</div>,
      },
      {
        accessorKey: 'labSetName',
        header: () => <div className="flex items-center">Name</div>,
      },
      {
        accessorKey: 'audienceSize',
        header: () => (
          <ColumnHeaderWithTooltip
            title="Audience Size"
            tooltip="The number of warm labs that should be provisioned. No warm labs will be provisioned beyond this value, unless Extra Labs is set. Good for one-time events that require a large number of labs."
          />
        ),
        cell: (props) => (
          <EditableCell
            value={props.getValue() as number}
            row={props.row}
            column={props.column}
            updateData={updateData}
          />
        ),
      },
      {
        accessorKey: 'extraLabCount',
        header: () => (
          <ColumnHeaderWithTooltip
            title="Extra Labs"
            tooltip="The number of extra warm labs that should always be available, regardless of how many deployed labs there are. Good for labs that should always have a small number of warm labs."
          />
        ),
        cell: (props) => (
          <EditableCell
            value={props.getValue() as number}
            row={props.row}
            column={props.column}
            updateData={updateData}
          />
        ),
      },
      {
        accessorKey: 'expirationHours',
        header: () => (
          <ColumnHeaderWithTooltip
            title="Duration (hrs)"
            tooltip="How long a lab lasts before being automatically destroyed. The user can extend the time."
          />
        ),
        cell: ({ getValue }) => getValue() || 'N/A',
      },
      {
        accessorKey: 'allowedGroups',
        header: () => <div className="flex items-center">Allowed Groups</div>,
        cell: ({ getValue }) => {
          const groups = getValue() as string[];
          if (groups.length === 0) {
            return 'Admins';
          }
          return groups ? groups.join(', ').replace('*', 'Everyone') : 'N/A';
        },
      },
      {
        accessorKey: 'description',
        header: () => <div className="flex items-center">Description</div>,
      },
      {
        accessorKey: 'imageUrl',
        header: () => <div className="flex items-center">Image URL</div>,
        cell: ({ getValue }) => <CopyButton value={getValue() as string} />,
      },
      {
        accessorKey: 'terraformS3Uri',
        header: () => <div className="flex items-center">Terraform S3 URI</div>,
        cell: ({ getValue }) => <CopyButton value={getValue() as string} />,
      },
      {
        accessorKey: 'guacamoleInstanceId',
        header: () => (
          <ColumnHeaderWithTooltip
            title="Guacamole Instance"
            tooltip="Which guacamole instance the lab is deployed to."
            tooltipPosition="right"
          />
        ),
      },
      {
        accessorKey: 'excludeFromCatalog',
        header: () => (
          <ColumnHeaderWithTooltip
            title="Hidden"
            tooltip="Hidden labs are not shown in the lab catalog."
            tooltipPosition="right"
          />
        ),
        cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
      },
      {
        accessorKey: 'loadingTimeSeconds',
        header: () => (
          <ColumnHeaderWithTooltip
            title="Loading Time (s)"
            tooltip="How long a loading screen is shown before the user interface tries connecting to the lab. This value is manually tuned."
            tooltipPosition="left"
          />
        ),
        cell: ({ getValue }) => getValue() || 'N/A',
      },
    ],
    [],
  );

  return (
    <AdminTable
      title={{
        text: 'Lab Configuration',
        icon: <FaTools />,
        tooltip:
          'All values except Audience Size and Extra Labs must be set in the config.yaml file present for each lab in the range-labs code repository.',
      }}
      columnVisibility={initialColumnVisibility}
      data={labSets}
      columns={columns}
      loading={loading}
      emptyMessage="No lab sets available."
    />
  );
};

export default LabConfig;
