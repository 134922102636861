import { Button } from '@headlessui/react';
import React, { useState, useEffect } from 'react';
import {
  FaLifeRing,
  FaDesktop,
  FaNetworkWired,
  FaTrash,
  FaExclamationTriangle,
  FaChevronRight,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import useApiClient from '../api/apiClient';
import DialogComponent from '../components/Dialog';
import { useLabContext } from '../context/LabContext';
import { useToast, ToastType } from '../context/ToastContext';

enum ModalState {
  MAIN,
  WORKSTATION_RESET,
  LAB_RESET,
  LAB_DELETE,
}

interface LabHelpDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const LabHelpDialog: React.FC<LabHelpDialogProps> = ({ isOpen, onClose }) => {
  const [modalState, setModalState] = useState<ModalState>(ModalState.MAIN);
  const { activeLabId, activeWorkstationId, labSetInstance } = useLabContext();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [actionIsPending, setActionIsPending] = useState(false);
  const { resetLabResource, deleteSelfOwnedLabSetInstance } = useApiClient();

  useEffect(() => {
    if (!isOpen) {
      const timer = setTimeout(() => setModalState(ModalState.MAIN), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleReset = async (resetUrl: string | undefined) => {
    if (!resetUrl) {
      console.error('Reset URL is missing');
      return;
    }

    try {
      await resetLabResource(resetUrl);
      const msg = 'A reset has been initiated. This may take some time.';
      showToast(msg, ToastType.SUCCESS, 10000);
      onClose();
    } catch (error) {
      console.error('Error resetting resource:', error);
    }
  };

  const handleWorkstationReset = async () => {
    const workstation = labSetInstance?.workstations.find(
      (ws) => ws.workstationId === activeWorkstationId,
    );
    await handleReset(workstation?.resetUrl);
  };

  const handleLabReset = async () => {
    const lab = labSetInstance?.labs.find((lab) => lab.labId === activeLabId);
    await handleReset(lab?.resetUrl);
  };

  const renderMainContent = () => (
    <div className="space-y-4">
      <ActionButton
        icon={<FaDesktop />}
        text="I can't use my remote desktop connection"
        onClick={() => setModalState(ModalState.WORKSTATION_RESET)}
      />
      <ActionButton
        icon={<FaNetworkWired />}
        text="I can't connect to a lab from my workstation"
        onClick={() => setModalState(ModalState.LAB_RESET)}
      />
      <ActionButton
        icon={<FaTrash />}
        text="I want to permanently delete my lab"
        onClick={() => setModalState(ModalState.LAB_DELETE)}
      />
    </div>
  );

  const handleLabDelete = async () => {
    if (!labSetInstance) {
      showToast(
        'Lab could not be deleted because no lab exists.',
        ToastType.FAILURE,
      );
      return;
    }

    try {
      await deleteSelfOwnedLabSetInstance(labSetInstance.labSetId);
      const msg = 'The lab deletion has been initiated.';
      showToast(msg, ToastType.SUCCESS, 10000);
      onClose();
      navigate('/');
    } catch (error) {
      console.error('Error deleting the lab:', error);
    }
  };

  const renderActionContent = (
    action: 'reset' | 'delete',
    resource: 'workstation' | 'lab',
    name: string,
    handleAction: () => void,
  ) => (
    <div className="space-y-6">
      <p className="text-lg text-gray-600">
        You&apos;re about to {action} the {resource} <b>{name}</b>.
      </p>
      <WarningMessage />
      <div className="flex justify-end space-x-3">
        {!actionIsPending && (
          <Button
            className="px-6 py-3 text-lg font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none"
            onClick={() => setModalState(ModalState.MAIN)}
          >
            Cancel
          </Button>
        )}
        <Button
          className={`px-6 py-3 text-lg font-medium text-white bg-red-600 border border-transparent rounded-md focus:outline-none ${
            actionIsPending
              ? 'opacity-75 cursor-not-allowed'
              : 'hover:bg-red-700'
          }`}
          disabled={actionIsPending}
          onClick={async () => {
            setActionIsPending(true);
            await handleAction();
            setActionIsPending(false);
          }}
        >
          {capitalize(action)} {capitalize(resource)}
          {actionIsPending && <span className="spinner" />}
        </Button>
      </div>
    </div>
  );

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const getLabName = (): string =>
    labSetInstance?.labs.find((lab) => lab.labId === activeLabId)?.labId ||
    activeLabId ||
    '';

  const renderContent = () => {
    switch (modalState) {
      case ModalState.WORKSTATION_RESET:
        return renderActionContent(
          'reset',
          'workstation',
          activeWorkstationId || '',
          handleWorkstationReset,
        );
      case ModalState.LAB_RESET:
        return renderActionContent(
          'reset',
          'lab',
          getLabName(),
          handleLabReset,
        );
      case ModalState.LAB_DELETE:
        return renderActionContent(
          'delete',
          'lab',
          labSetInstance?.labSetId || 'Unknown Lab',
          handleLabDelete,
        );
      default:
        return renderMainContent();
    }
  };

  const getTitle = () => {
    const titles: Record<ModalState, string> = {
      [ModalState.MAIN]: 'Lab Help',
      [ModalState.WORKSTATION_RESET]: 'Reset Workstation',
      [ModalState.LAB_RESET]: 'Reset Lab',
      [ModalState.LAB_DELETE]: 'Delete Lab',
    };
    return titles[modalState];
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className="flex items-center text-gray-900">
          <FaLifeRing className="mr-2 text-blue-600 text-2xl" />
          <span className="text-2xl font-semibold">{getTitle()}</span>
        </div>
      }
    >
      {renderContent()}
    </DialogComponent>
  );
};

interface ActionButtonProps {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
  href?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  text,
  onClick,
  href,
}) => {
  const buttonProps = {
    className:
      'w-full flex items-center justify-between px-6 py-4 text-left text-lg font-normal text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none',
    onClick,
  };

  const content = (
    <>
      <div className="flex items-center">
        {React.cloneElement(icon as React.ReactElement, {
          className: 'mr-3 text-gray-400',
        })}
        <span>{text}</span>
      </div>
      <FaChevronRight className="text-gray-400" />
    </>
  );

  return href ? (
    <a
      href={href}
      {...buttonProps}
      className={`${buttonProps.className} no-underline`}
    >
      {content}
    </a>
  ) : (
    <Button {...buttonProps}>{content}</Button>
  );
};

const WarningMessage: React.FC = () => (
  <div className="bg-yellow-50 border-l-4 border-yellow-400 py-2 px-4 flex items-center">
    <FaExclamationTriangle className="text-yellow-400 text-3xl flex-shrink-0" />
    <div className="ml-3">
      <p className="text-lg text-yellow-700">
        This action will erase all changes made. Any progress will be lost.
        Proceed with caution.
      </p>
    </div>
  </div>
);

export default LabHelpDialog;
