import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { useSearchParams, useNavigate } from 'react-router-dom';

import useApiClient from '../../../api/apiClient';
import { GuacamoleRecording } from '../../../models/GuacamoleRecording';

export const Recordings = () => {
  const [searchParams] = useSearchParams();
  const labSetId = searchParams.get('lab_set_instance_id');
  const apiClient = useApiClient();
  const [recordings, setRecordings] = useState<GuacamoleRecording[]>([]);
  const [selectedRecording, setSelectedRecording] =
    useState<GuacamoleRecording | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!labSetId) {
      navigate('/admin/history');
      return;
    }

    apiClient
      .getRecordingsForLab(labSetId)
      .then((data) => setRecordings(data))
      .catch((err) => {
        console.error('Error fetching recordings:', err);
        setError(
          'Failed to load recordings. Recordings are synced every hour. Please try again later.',
        );
      })
      .finally(() => setIsLoading(false));
  }, [labSetId]);

  return (
    <div className="h-[calc(100vh-4rem)] w-full bg-gray-900 relative overflow-x-hidden">
      {/* Menu Handle */}
      <div
        className={`fixed left-0 top-1/2 -translate-y-1/2 w-6 h-12 z-50 flex items-center justify-center 
          bg-gray-800 rounded-r-md border-r border-t border-b border-gray-700 cursor-pointer 
          hover:bg-gray-700 transition-all duration-200 ${isMenuOpen ? 'opacity-0' : 'opacity-100'}`}
        onMouseEnter={() => setIsMenuOpen(true)}
      >
        <FaBars className="text-gray-400" />
      </div>

      {/* Recordings Menu */}
      <div
        className={`fixed left-0 bottom-0 h-[calc(100vh-4rem)] w-80 bg-gray-800 shadow-xl 
          transform transition-transform duration-300 ease-in-out border-r border-gray-700 
          ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}
        onMouseLeave={() => setIsMenuOpen(false)}
      >
        <div className="p-4 h-full overflow-y-auto">
          <h2 className="text-xl font-bold text-white mb-4">Recordings</h2>
          <div className="space-y-2">
            {isLoading ? (
              <div className="text-center py-6">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400 mx-auto"></div>
              </div>
            ) : error ? (
              <div className="text-red-500 p-4 text-center">{error}</div>
            ) : recordings.length === 0 ? (
              <div className="text-gray-400 p-4 text-center">
                No recordings found
              </div>
            ) : (
              recordings.map((recording) => {
                const recordingDate = new Date(recording.startTimestamp * 1000);
                return (
                  <button
                    key={recording.labSetInstanceId}
                    onClick={() => {
                      setSelectedRecording(recording);
                      setIsMenuOpen(false); // Close menu when selecting a recording
                    }}
                    className="w-full p-3 text-left rounded-lg bg-gray-700 hover:bg-gray-600 
                      transition-all duration-200 border border-transparent hover:border-blue-500"
                  >
                    <div className="font-medium text-white">
                      {recording.workstationId}
                    </div>
                    <div className="text-sm text-gray-300 flex items-center gap-2">
                      <span>{recordingDate.toLocaleDateString()}</span>
                      <span className="text-base font-medium text-blue-400">
                        {recordingDate.toLocaleTimeString()}
                      </span>
                    </div>
                  </button>
                );
              })
            )}
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="h-full w-full flex items-center justify-center">
        {selectedRecording ? (
          <iframe
            src={selectedRecording.iframeUrl}
            className="w-full h-full border-0"
            title="Recording Playback"
          />
        ) : (
          <div
            className="text-gray-300 text-lg bg-gray-800 p-8 rounded-xl shadow-sm border border-gray-700"
            onClick={() => setIsMenuOpen(true)}
          >
            Select a recording from the menu to begin playback
          </div>
        )}
      </div>
    </div>
  );
};

export default Recordings;
