import React from 'react';
import { FaFlask, FaClock, FaCog } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

import Header from '../../../components/Header';

const AdminHeader: React.FC = () => {
  const navLinks = [
    { label: 'Live Labs', icon: <FaFlask />, path: '/admin/live' },
    { label: 'Lab Config', icon: <FaCog />, path: '/admin/lab-config' },
    { label: 'History', icon: <FaClock />, path: '/admin/history' },
  ];

  return (
    <Header>
      <div className="flex items-center gap-4">
        {navLinks.map((link) => (
          <NavLink
            key={link.label}
            to={link.path}
            className={({ isActive }) =>
              `flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                isActive
                  ? 'bg-blue-700 text-white'
                  : 'text-white hover:bg-blue-600'
              }`
            }
          >
            <span className="mr-2">{link.icon}</span>
            {link.label}
          </NavLink>
        ))}
        <div className="border-l border-gray-500 m-4 h-10"></div>
      </div>
    </Header>
  );
};

export default AdminHeader;
