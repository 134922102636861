import React from 'react';

import { useLabContext } from '../context/LabContext';
import { ResourceState } from '../models';

import WorkstationOption from './WorkstationOption';

const WorkstationSelect: React.FC = () => {
  const {
    labSetInstance,
    activeLabId,
    activeWorkstationId,
    isJoinedLab,
    setActiveWorkstationId,
    setLoadingMessage,
  } = useLabContext();

  if (!labSetInstance || !labSetInstance.workstations || !activeLabId) {
    return null;
  }

  const activeLab = labSetInstance.labs.find(
    (lab) => lab.labId === activeLabId,
  );

  if (!activeLab) {
    return null;
  }

  // Translate a list of workstationIds to a list of workstation objects
  const workstationOptions = [];
  for (const workstationId of activeLab.workstationIds) {
    const workstation = labSetInstance.workstations.find(
      (workstation) => workstation.workstationId === workstationId,
    );

    if (workstation) {
      workstationOptions.push(workstation);
    }
  }

  const handlesetActiveWorkstationId = (workstationId: string) => {
    // Ignore click if the workstation is already active or if this is a joined lab
    if (activeWorkstationId === workstationId || isJoinedLab) {
      return;
    }
    setActiveWorkstationId(workstationId);
  };

  const deployedWorkstations = workstationOptions.filter(
    (workstation) => workstation.state === ResourceState.DEPLOYED,
  );

  if (deployedWorkstations.length === 0) {
    // If there are no deployed workstations, at least one must be recreating
    setLoadingMessage('Recreating your workstation');
    return null;
  } else {
    // Clear the loading message if at least one workstation is not recreating
    setLoadingMessage('');
  }

  // If there's only one workstation, do not render the selector
  if (workstationOptions.length <= 1) {
    return null;
  }

  // Case 1: No active workstation is set. Set the first deployed workstation as active.
  if (!activeWorkstationId) {
    handlesetActiveWorkstationId(deployedWorkstations[0].workstationId);
  }

  // Case 2: Active workstation is set but not deployed. Set the first deployed
  // workstation as active.
  const activeWorkstation = workstationOptions.find(
    (workstation) => workstation.workstationId === activeWorkstationId,
  );

  if (
    !activeWorkstation ||
    activeWorkstation.state !== ResourceState.DEPLOYED
  ) {
    handlesetActiveWorkstationId(deployedWorkstations[0].workstationId);
    return null;
  }

  return (
    <div className="relative">
      <WorkstationOption
        activeWorkstation={activeWorkstation}
        options={workstationOptions}
        onOptionClick={handlesetActiveWorkstationId}
        disabled={isJoinedLab}
      />
    </div>
  );
};

export default WorkstationSelect;
