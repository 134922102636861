import axios from 'axios';
import { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

import { useToast, ToastType } from '../context/ToastContext';
import {
  JoinableLabEntry,
  LabSetInstance,
  Task,
  GuacamoleRecording,
} from '../models';
import { GuacamoleTokenDetails } from '../models/GuacamoleTokenDetails';
import { LabSet } from '../models/LabSet';

const HELP_SUFFIX = 'Please contact the Cyber Range team for support.';

export class NotFoundError extends Error {
  constructor() {
    super('Not found.');
    this.name = 'NotFoundError';
  }
}

export class BadRequestError extends Error {
  constructor() {
    super('Bad request.');
    this.name = 'BadRequestError';
  }
}

export class TooManyRequestsError extends Error {
  constructor() {
    super('Too many requests.');
    this.name = 'TooManyRequestsError';
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super('Unauthorized.');
    this.name = 'UnauthorizedError';
  }
}

const useApiClient = () => {
  const { token, logIn } = useContext<IAuthContext>(AuthContext);
  const { showToast } = useToast();

  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || '',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  apiClient.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  const handleError = (error: any) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          throw new BadRequestError();
        case 401:
          showToast(
            "Your session has expired. You'll be redirected to log in shortly.",
            ToastType.WARNING,
            5000,
          );
          setTimeout(logIn, 5000);
          break;
        case 403:
          throw new UnauthorizedError();
        case 404:
          throw new NotFoundError();
        case 429:
          throw new TooManyRequestsError();
        default:
          break;
      }
    }

    // Axios errors often occur when network requests are interrupted by the login
    // flow. To avoid showing an error message to the user, we simply log the error
    // and return.
    if (axios.isAxiosError(error)) {
      console.error(error);
      return;
    }

    const msg = `An unknown error occurred. ${HELP_SUFFIX}`;
    showToast(msg, ToastType.FAILURE, 10000);
    console.error(error);
    throw error;
  };

  const apiCall = async (
    method: 'get' | 'post' | 'delete',
    url: string,
    data?: any,
  ) => {
    try {
      const response = await apiClient[method](url, data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };

  const createLabSetInstance = (labSetId: string): Promise<LabSetInstance> =>
    apiCall('post', `/self-service/${labSetId}`);

  const resetLabResource = (resetUrl: string): Promise<void | null> =>
    apiCall('get', resetUrl);

  const deleteSelfOwnedLabSetInstance = (
    labSetId: string,
  ): Promise<void | null> =>
    apiCall('get', `/self-service/destroy/${labSetId}`);

  const findLabSetInstance = (labSetId: string): Promise<LabSetInstance> =>
    apiCall('get', `/self-service/find/${labSetId}`);

  const getLabSetInstances = (): Promise<LabSetInstance[]> =>
    apiCall('get', '/self-service/lab-set-instances');

  const extendLabTime = (labSetId: string): Promise<null> =>
    apiCall('get', `/self-service/extend-expiration/${labSetId}`);

  const getLabCatalog = (): Promise<LabSet[]> =>
    apiCall('get', '/self-service/lab-catalog');

  const getGuacamoleTokenDetailsForLab = (
    labSetId: string,
    join = false,
  ): Promise<GuacamoleTokenDetails | null> =>
    apiCall('get', `/self-service/guacamole-token/${labSetId}?join=${join}`);

  const shareLab = (
    labSetInstanceId: string,
    readOnly: boolean,
  ): Promise<JoinableLabEntry> =>
    apiCall('post', '/self-service/join-code', {
      labSetInstanceId,
      readOnly,
    });

  const getLabSets = (): Promise<LabSet[]> =>
    apiCall('get', '/self-service/admin/lab-sets');

  const upsertLabSet = (labSet: LabSet): Promise<LabSet> =>
    apiCall('post', '/self-service/admin/lab-sets', labSet);

  const getTasksForMonth = (month: string): Promise<Task[]> =>
    apiCall('get', `/self-service/admin/tasks?month=${month}`);

  const deleteLabSetInstance = (labSetInstanceId: string): Promise<void> =>
    apiCall(
      'delete',
      `/self-service/admin/lab-set-instances/${labSetInstanceId}`,
    );

  const resetLabSetInstance = (
    labSetInstanceId: string,
    module: string,
  ): Promise<void> =>
    apiCall(
      'get',
      `/self-service/admin/lab-set-instances/reset/${labSetInstanceId}?module=${module}`,
    );

  const getRecordingsForLab = (
    labSetId: string,
  ): Promise<GuacamoleRecording[]> =>
    apiCall('get', `/self-service/admin/recordings/${labSetId}`);

  return {
    createLabSetInstance,
    resetLabResource,
    deleteSelfOwnedLabSetInstance,
    findLabSetInstance,
    getLabSetInstances,
    extendLabTime,
    getLabCatalog,
    getGuacamoleTokenDetailsForLab,
    getLabSets,
    upsertLabSet,
    getTasksForMonth,
    deleteLabSetInstance,
    resetLabSetInstance,
    getRecordingsForLab,
    shareLab,
  };
};

export default useApiClient;
