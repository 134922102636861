import clsx from 'clsx';
import React, { useState, useRef, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';

import KaliIcon from '../assets/icons/kali.svg';
import LinuxIcon from '../assets/icons/linux.svg';
import RhelIcon from '../assets/icons/rhel.svg';
import UbuntuIcon from '../assets/icons/ubuntu.svg';
import UnknownIcon from '../assets/icons/unknown.svg';
import WindowsIcon from '../assets/icons/windows.svg';
import { WorkstationIcon, Workstation, ResourceState } from '../models';

const iconMap = {
  [WorkstationIcon.WINDOWS]: WindowsIcon,
  [WorkstationIcon.LINUX]: LinuxIcon,
  [WorkstationIcon.RHEL]: RhelIcon,
  [WorkstationIcon.UBUNTU]: UbuntuIcon,
  [WorkstationIcon.KALI]: KaliIcon,
};

interface WorkstationOptionProps {
  activeWorkstation: Workstation;
  options: Workstation[];
  onOptionClick: (workstationId: string) => void;
  disabled?: boolean;
}

const WorkstationOption: React.FC<WorkstationOptionProps> = ({
  activeWorkstation,
  options,
  onOptionClick,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const renderIcon = (icon: string) => {
    const iconSrc = iconMap[icon as WorkstationIcon] || UnknownIcon;
    return (
      <img
        src={iconSrc}
        alt={icon}
        className="w-6 h-6 transition-transform duration-200 ease-in-out group-hover:scale-110"
        draggable={false}
      />
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOptionClick = (workstationId: string) => {
    const clickedWorkstation = options.find(
      (option) => option.workstationId === workstationId,
    );

    if (!clickedWorkstation) {
      return;
    }

    if (clickedWorkstation.state === ResourceState.DEPLOYED) {
      onOptionClick(workstationId);
      setIsOpen(false);
    }
  };

  return (
    <div ref={componentRef} className="relative">
      <div
        data-testid={activeWorkstation.workstationId}
        className={clsx(
          'group p-2 flex flex-col items-center justify-center rounded-lg transition-all duration-300 ease-in-out bg-gray-200',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        )}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        onMouseEnter={() => !disabled && setIsOpen(true)}
      >
        {renderIcon(activeWorkstation.icon)}
      </div>

      {isOpen && (
        <div
          className="absolute mt-2 min-w-[16rem] bg-gray-50 border border-gray-200 rounded-lg shadow-xl z-50 whitespace-nowrap"
          style={{
            top: '100%',
            left: '0',
          }}
        >
          <div className="px-4 py-3 text-base font-medium text-gray-500 border-b border-gray-200">
            Switch Workstation
          </div>
          {options.map((option) => (
            <div
              key={option.workstationId}
              className={clsx(
                'px-4 py-3 flex items-center gap-4 hover:bg-gray-200 first:rounded-t-lg last:rounded-b-lg',
                option.workstationId === activeWorkstation.workstationId &&
                  'font-medium',
                option.state === ResourceState.DEPLOYED && !disabled
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed',
              )}
              onClick={() => handleOptionClick(option.workstationId)}
            >
              <div className="w-6 flex items-center justify-center">
                {option.state === ResourceState.DEPLOYED ? (
                  renderIcon(option.icon)
                ) : (
                  <div
                    data-testid="workstation-option-spinner"
                    className="w-6 h-6 border-4 border-t-4 border-gray-200 border-t-black rounded-full animate-spin cursor-not-allowed"
                  ></div>
                )}
              </div>
              <span className="text-base text-black flex-grow">
                {option.workstationId}
              </span>
              {option.workstationId === activeWorkstation.workstationId && (
                <FaCheck className="w-4 h-4 text-blue-500" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WorkstationOption;
