import React, { useState, useEffect } from 'react';
import { FaClock } from 'react-icons/fa';

import useApiClient from '../api/apiClient';
import { useLabContext } from '../context/LabContext';

import DialogComponent from './Dialog';

const ExtendTimeDialog: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [isExtending, setIsExtending] = useState(false);
  const { labSetInstance } = useLabContext();
  const { extendLabTime } = useApiClient();

  useEffect(() => {
    const checkExpiration = () => {
      if (labSetInstance?.expires) {
        const currentTime = Math.floor(Date.now() / 1000);
        const remaining = labSetInstance.expires - currentTime;
        const fifteenMinutes = 15 * 60;

        setTimeRemaining(remaining);

        if (remaining <= fifteenMinutes) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      }
    };

    const timer = setInterval(checkExpiration, 60000);
    checkExpiration();

    return () => clearInterval(timer);
  }, [labSetInstance?.expires]);

  const handleExtendTime = async () => {
    setIsExtending(true);
    try {
      if (labSetInstance) {
        await extendLabTime(labSetInstance.labSetId);
        setIsOpen(false);
      }
    } catch (error) {
      console.error('Error extending lab time:', error);
    } finally {
      setIsExtending(false);
    }
  };

  const formatTime = (seconds: number): string => {
    const nonNegativeSeconds = Math.max(seconds, 0);
    const minutes = Math.floor(nonNegativeSeconds / 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
      title={
        <div className="flex items-center text-gray-900">
          <FaClock className="mr-3 text-yellow-600 text-3xl" />
          <span className="text-2xl font-bold">Time&apos;s Almost Up!</span>
        </div>
      }
    >
      <div className="space-y-6 p-4">
        <p className="text-lg text-gray-700 leading-relaxed">
          Your lab session will expire in approximately{' '}
          <span className="font-semibold text-yellow-600">
            {timeRemaining !== null && formatTime(timeRemaining)}
          </span>
          . Would you like to extend your time and continue working?
        </p>
        <div className="flex justify-center">
          <button
            className="px-8 py-3 text-lg font-medium text-white bg-primary-600 border border-transparent rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleExtendTime}
            disabled={isExtending}
          >
            <span className="flex items-center justify-center">
              Extend My Session
              {isExtending && <span className="spinner ml-2" />}
            </span>
          </button>
        </div>
      </div>
    </DialogComponent>
  );
};

export default ExtendTimeDialog;
