import React, { useState, useMemo } from 'react';
import { FaArrowRight } from 'react-icons/fa';

import fallback1 from '../assets/images/fallback_lab_images/fallback1.webp';
import fallback2 from '../assets/images/fallback_lab_images/fallback2.webp';
import fallback3 from '../assets/images/fallback_lab_images/fallback3.webp';
import fallback4 from '../assets/images/fallback_lab_images/fallback4.webp';
import fallback5 from '../assets/images/fallback_lab_images/fallback5.webp';
import fallback6 from '../assets/images/fallback_lab_images/fallback6.webp';
import { useLabContext } from '../context/LabContext';

const fallbackImages = [
  fallback1,
  fallback2,
  fallback3,
  fallback4,
  fallback5,
  fallback6,
];

const Catalog: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { labCatalog } = useLabContext();

  const allTags = useMemo(() => {
    const tagSet = new Set<string>();
    labCatalog?.forEach((lab) => {
      lab.tags.forEach((tag) => tagSet.add(tag));
    });
    return Array.from(tagSet).sort();
  }, [labCatalog]);

  const toggleTag = (tag: string) => {
    setSelectedTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      } else {
        return [...prev, tag];
      }
    });
  };

  const filteredLabs = labCatalog?.filter((lab) => {
    const query = searchQuery.toLowerCase();
    const matchesSearch =
      lab.labSetName.toLowerCase().includes(query) ||
      lab.description.toLowerCase().includes(query) ||
      lab.tags.some((tag) => tag.toLowerCase().includes(query));
    const matchesTags =
      selectedTags.length === 0 ||
      selectedTags.every((t) =>
        lab.tags.map((tag) => tag.toLowerCase()).includes(t.toLowerCase()),
      );

    return matchesSearch && matchesTags;
  });

  const handleDeploy = (labSetId: string) => {
    // We use a page refresh instead of React Router due to issues with Guacamole
    // persistent storage and cookies not being updated properly, resulting in
    // 403 errors on the Guacamole token endpoint.
    window.location.href = `/lab/${labSetId}`;
  };

  const getRandomFallbackImage = (index: number) => {
    return fallbackImages[index % fallbackImages.length];
  };

  return (
    <div className="flex flex-col h-full bg-slate-800 p-4 text-white">
      <h2 className="text-3xl font-bold mb-6 text-white">Lab Catalog</h2>
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search labs..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-3 bg-slate-700 border border-slate-600 rounded focus:outline-none focus:border-slate-500 transition-colors placeholder:text-slate-400 mb-4"
        />
        <div className="flex flex-wrap gap-2">
          {allTags.map((tag) => (
            <button
              key={tag}
              onClick={() => toggleTag(tag)}
              className={`h-10 px-4 text-sm flex items-center rounded-full transition-colors ${
                selectedTags.includes(tag)
                  ? 'bg-slate-600 text-white font-semibold'
                  : 'bg-slate-700 text-slate-300 hover:bg-slate-600'
              }`}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>

      {!labCatalog ? (
        <div className="flex-1 overflow-auto">
          {[...Array(5)].map((_, idx) => (
            <div
              key={idx}
              className="flex items-center justify-between border-b border-slate-600 py-4 px-3 animate-pulse"
            >
              <div className="flex items-center space-x-4">
                <div className="h-24 w-24 bg-slate-600 rounded"></div>
                <div>
                  <div className="h-6 bg-slate-600 rounded w-48 mb-3"></div>
                  <div className="h-4 bg-slate-500 rounded w-36"></div>
                  <div className="h-4 bg-slate-500 rounded w-24 mt-2"></div>
                </div>
              </div>
              <div className="h-10 w-32 bg-slate-600 rounded"></div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex-1 overflow-auto">
          {filteredLabs?.map((lab, idx) => {
            const imageUrl = lab.imageUrl
              ? lab.imageUrl
              : getRandomFallbackImage(idx);
            return (
              <div
                key={lab.labSetId}
                className="border border-slate-500 rounded-lg mb-4 bg-slate-700 hover:shadow-lg transition-shadow group/image"
              >
                <div className="relative">
                  <img
                    src={imageUrl}
                    alt={lab.labSetName}
                    className="w-full h-48 object-cover rounded-t-lg transition-opacity duration-300 group-hover/image:opacity-50"
                  />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover/image:opacity-100 transition-opacity duration-300">
                    <button
                      onClick={() => handleDeploy(lab.labSetId)}
                      className="group text-white bg-black/50 hover:bg-black/70 px-4 py-2 rounded-full flex items-center transition-all duration-300 group/deploy"
                    >
                      Deploy Now
                      <FaArrowRight className="ml-2 transition-transform duration-300 group-hover/deploy:translate-x-1" />
                    </button>
                  </div>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-xl font-semibold text-white">
                      {lab.labSetName}
                    </span>
                    <div className="flex flex-wrap gap-2">
                      {lab.tags.map((tag) => (
                        <span
                          key={tag}
                          className="text-xs bg-slate-600 text-white rounded-full px-3 py-1"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                  <p className="text-sm text-slate-300 mb-3">
                    {lab.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Catalog;
