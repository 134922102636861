import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { FaCog, FaSearch, FaCaretUp, FaCaretDown } from 'react-icons/fa';
import {
  FiChevronRight,
  FiChevronLeft,
  FiChevronsRight,
  FiChevronsLeft,
  FiInfo,
} from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

interface FilterPill {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

interface TableTitle {
  text: string;
  icon: React.ReactNode;
  tooltip?: string;
}

export interface AdminTableProps<T> {
  title: TableTitle;
  data: T[];
  columns: ColumnDef<T>[];
  loading?: boolean;
  enablePagination?: boolean;
  emptyMessage?: string;
  globalFilterPlaceholder?: string;
  filterPills?: FilterPill[];
  columnVisibility?: Record<string, boolean>;
  showDetailedViewToggle?: boolean;
  onDetailedViewChange?: (checked: boolean) => void;
  isDetailedView?: boolean;
}

interface ColumnVisibilityMenuProps {
  table: any;
  isOpen: boolean;
  onClose: () => void;
}

const ColumnVisibilityMenu: React.FC<ColumnVisibilityMenuProps> = ({
  table,
  isOpen,
  onClose,
}) => {
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div
      ref={menuRef}
      className="absolute right-0 top-0 -translate-y-2 w-56 rounded-lg shadow-xl bg-white border border-gray-200 z-50"
    >
      <div className="pt-0 pb-2" role="menu">
        <div className="px-4 py-3 text-sm font-semibold text-blue-900 bg-blue-50 border-b border-gray-200 flex justify-between items-center">
          Column Visibility
          <button
            onClick={onClose}
            className="text-blue-600 hover:text-blue-800"
          >
            <IoClose className="w-5 h-5" />
          </button>
        </div>
        {table.getAllLeafColumns().map((column: any) => {
          const isVisible = column.getIsVisible();
          return (
            <label
              key={column.id}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-blue-50 cursor-pointer border-b border-gray-50 last:border-b-0"
            >
              <input
                type="checkbox"
                className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                checked={isVisible}
                onChange={(e) => {
                  column.toggleVisibility(e.target.checked);
                }}
              />
              {column.columnDef.accessorKey || column.id}
            </label>
          );
        })}
      </div>
    </div>
  );
};

const Title: React.FC<TableTitle> = ({ text, icon, tooltip }) => (
  <h1 className="text-2xl font-bold mb-4 text-blue-800 flex items-center justify-between w-full">
    <div className="flex items-center">
      {React.cloneElement(icon as React.ReactElement, { className: 'mr-2' })}
      {text}
      {tooltip && (
        <div className="relative group ml-2">
          <FiInfo className="text-blue-600 hover:text-blue-800" />
          <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity z-50 max-w-[calc(100vw-2rem)] whitespace-normal break-words">
            {tooltip}
          </div>
        </div>
      )}
    </div>
  </h1>
);

const LoadingSkeleton: React.FC = () => (
  <div className="animate-pulse">
    <div className="mb-4 flex items-center">
      <div className="w-6 h-6 bg-blue-200 rounded mr-2"></div>
      <div className="h-10 bg-blue-200 rounded w-full"></div>
    </div>
    <div className="min-w-full bg-white shadow-md rounded">
      <div className="bg-blue-200 h-12 flex">
        {Array.from({ length: 6 }).map((_, index) => (
          <div
            key={`header-${index}`}
            className="flex-1 p-4 border-b border-blue-100"
          >
            <div className="h-4 bg-blue-300 rounded w-2/3"></div>
          </div>
        ))}
      </div>
      {Array.from({ length: 5 }).map((_, rowIndex) => (
        <div
          key={`row-${rowIndex}`}
          className={`flex ${rowIndex % 2 === 0 ? 'bg-white' : 'bg-blue-50'}`}
        >
          {Array.from({ length: 6 }).map((_, colIndex) => (
            <div
              key={`cell-${rowIndex}-${colIndex}`}
              className="flex-1 p-4 border-b border-blue-100"
            >
              <div className="h-4 bg-blue-100 rounded w-3/4"></div>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

export const AdminTable = <T,>({
  title,
  data,
  columns,
  loading = false,
  enablePagination = true,
  emptyMessage = 'No data available.',
  globalFilterPlaceholder = 'Search...',
  filterPills,
  columnVisibility: initialColumnVisibility,
  showDetailedViewToggle = false,
  onDetailedViewChange,
  isDetailedView = false,
}: AdminTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnVisibilityMenuOpen, setColumnVisibilityMenuOpen] =
    useState(false);
  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >(initialColumnVisibility || {});

  useEffect(() => {
    if (initialColumnVisibility) {
      setColumnVisibility(initialColumnVisibility);
    }
  }, [initialColumnVisibility]);

  const tableConfig = {
    data,
    columns,
    state: {
      globalFilter,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    ...(enablePagination && {
      getPaginationRowModel: getPaginationRowModel(),
    }),
  };

  const table = useReactTable(tableConfig);

  return (
    <div className="m-8 p-6 bg-blue-100 rounded shadow-lg">
      <div className="relative">
        <div className="flex justify-between">
          <Title {...title} />
          <div className="flex items-center gap-8">
            {showDetailedViewToggle && (
              <label className="flex items-center gap-3 text-blue-800 whitespace-nowrap">
                <input
                  type="checkbox"
                  checked={isDetailedView}
                  onChange={(e) => onDetailedViewChange?.(e.target.checked)}
                  className="form-checkbox h-4 w-4 text-blue-600 rounded border-blue-300 focus:ring-blue-500"
                />
                <span className="text-sm font-medium">Detailed View</span>
              </label>
            )}
            <div className="relative">
              <button
                onClick={() =>
                  setColumnVisibilityMenuOpen(!columnVisibilityMenuOpen)
                }
                className="p-2 text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-100 flex items-center"
              >
                <FaCog className="w-6 h-6" />
              </button>
              <ColumnVisibilityMenu
                table={table}
                isOpen={columnVisibilityMenuOpen}
                onClose={() => setColumnVisibilityMenuOpen(false)}
              />
            </div>
          </div>
        </div>
      </div>

      {filterPills && filterPills.length > 0 && (
        <div className="mb-4 flex flex-wrap gap-2">
          {filterPills.map((pill, index) => (
            <button
              key={index}
              onClick={pill.onClick}
              className={`px-4 py-2 rounded-full text-sm font-semibold ${
                pill.isActive
                  ? 'bg-blue-600 text-white'
                  : 'bg-blue-200 text-blue-800 hover:bg-blue-300'
              }`}
            >
              {pill.label}
            </button>
          ))}
        </div>
      )}

      {loading ? (
        <LoadingSkeleton />
      ) : data.length === 0 ? (
        <p className="text-blue-700">{emptyMessage}</p>
      ) : (
        <>
          <div className="mb-4 flex items-center">
            <FaSearch className="text-blue-500 mr-2" />
            <input
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder={globalFilterPlaceholder}
              className="border border-blue-300 bg-white text-blue-800 p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <table className="w-full bg-white shadow-md rounded table-auto">
            <thead className="bg-blue-300">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="py-2 px-4 border-b text-left cursor-pointer text-blue-700"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex items-center">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getIsSorted() &&
                          (header.column.getIsSorted() === 'desc' ? (
                            <FaCaretDown className="ml-2 self-center" />
                          ) : (
                            <FaCaretUp className="ml-2 self-center" />
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, i) => (
                <tr
                  key={row.id}
                  className={i % 2 === 0 ? 'bg-white' : 'bg-blue-50'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="py-2 px-4 border-b text-blue-800"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {enablePagination && (
            <div className="mt-4 flex items-center justify-between px-4">
              <div className="flex items-center gap-2">
                {[
                  {
                    icon: <FiChevronsLeft />,
                    onClick: () => table.setPageIndex(0),
                    disabled: !table.getCanPreviousPage(),
                  },
                  {
                    icon: <FiChevronLeft />,
                    onClick: () => table.previousPage(),
                    disabled: !table.getCanPreviousPage(),
                  },
                  {
                    icon: <FiChevronRight />,
                    onClick: () => table.nextPage(),
                    disabled: !table.getCanNextPage(),
                  },
                  {
                    icon: <FiChevronsRight />,
                    onClick: () => table.setPageIndex(table.getPageCount() - 1),
                    disabled: !table.getCanNextPage(),
                  },
                ].map((button, index) => (
                  <button
                    key={index}
                    className="px-4 py-2 text-sm bg-blue-500 text-white rounded disabled:bg-blue-300"
                    onClick={button.onClick}
                    disabled={button.disabled}
                  >
                    {button.icon}
                  </button>
                ))}
              </div>
              <span className="flex items-center gap-1 text-blue-800">
                <div>Page</div>
                <strong>
                  {table.getState().pagination.pageIndex + 1} of{' '}
                  {table.getPageCount()}
                </strong>
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminTable;
