import React, { useState } from 'react';
import { FaCopy, FaCheck } from 'react-icons/fa';

interface CopyButtonProps {
  value: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <div className="flex items-center gap-2">
      <span>{value}</span>
      <button
        onClick={handleCopy}
        className={`${
          copied ? 'text-green-500' : 'text-gray-500 hover:text-gray-700'
        }`}
        title="Copy to clipboard"
      >
        {copied ? <FaCheck size={14} /> : <FaCopy size={14} />}
      </button>
    </div>
  );
};

export default CopyButton;
