import clsx from 'clsx';
import React from 'react';

interface ActionButtonProps {
  onClick: () => void;
  disabled: boolean;
  icon: React.ReactNode;
  text: string;
  loading?: boolean;
  variant: 'primary' | 'secondary' | 'warning' | 'danger';
  minWidth?: boolean;
}

export const getButtonClasses = (
  baseColor: string,
  _hoverColor: string, // Prefixed with _ to indicate intentionally unused
  isDisabled: boolean,
  extraClasses?: string,
) => {
  const getColorClasses = (
    baseColor: 'blue-600' | 'gray-500' | 'yellow-500' | 'red-500',
  ) => {
    const colorClasses: Record<typeof baseColor, string> = {
      'blue-600': 'bg-blue-600 hover:bg-blue-700',
      'gray-500': 'bg-gray-500 hover:bg-gray-600',
      'yellow-500': 'bg-yellow-500 hover:bg-yellow-600',
      'red-500': 'bg-red-500 hover:bg-red-600',
    };
    return colorClasses[baseColor] || '';
  };

  return clsx(
    'flex items-center text-white py-1 px-3 rounded transition',
    getColorClasses(
      baseColor as 'blue-600' | 'gray-500' | 'yellow-500' | 'red-500',
    ),
    isDisabled && 'opacity-50 cursor-not-allowed',
    extraClasses,
  );
};

const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  disabled,
  icon,
  text,
  loading,
  variant,
  minWidth,
}) => {
  const colorMap = {
    primary: ['blue-600', 'blue-700'],
    secondary: ['gray-500', 'blue-600'],
    warning: ['yellow-500', 'yellow-600'],
    danger: ['red-500', 'red-600'],
  };

  const [baseColor, hoverColor] = colorMap[variant];

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={getButtonClasses(
        baseColor,
        hoverColor,
        disabled,
        minWidth ? 'justify-center space-x-2 min-w-[120px]' : '',
      )}
    >
      {loading ? (
        <span className="spinner mr-2"></span>
      ) : (
        <>
          {icon}
          <span>{text}</span>
        </>
      )}
    </button>
  );
};

export default ActionButton;
